import React, { useEffect, useState } from 'react'
import * as ClikTracker from "../utils/cliktracker";
import { Link, useHistory } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { Breadcrumb, Button, Col, Container, Form, FormControl, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";
import { AnimationLoading } from '../components/Lottie';
import Detail from '../components/Detail';
import { Chip } from '@mui/material';
import swal from 'sweetalert';
import {Checkbox} from '@mui/material';

function PageGroupAdd() {

 
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [funnel, setFunnel] = useState(false);
    const [links, setLinks] = useState([]);
    const [groupLinks, setGroupLinks] = useState([]);
    const history = useHistory();


    useEffect(() => {
        const run = async ()=>{
            try {
                const links = await ClikTracker.getClikTrackerLinks();
                setLinks(links);
            } catch (e) {
                console.log(e.message);
                setLinks([])
            }      
        }   
        run();
    }, []);


    const onToggleLinkToGroup = (cliktrackerId, include) => {

        if(include){
            const link = groupLinks.find(id=> id === cliktrackerId);
            if(!link){
                setGroupLinks([...groupLinks, cliktrackerId]);
            }
        }else{
            const list = [];
            groupLinks.forEach(link => {
                if(link !== cliktrackerId){
                    list.push(link);
                }
            })
            setGroupLinks(list);
        }
    }

    const onAdd = async (e) =>{ 
        e.preventDefault();
        const name = e.target.name.value;
 
                try{
                    setLoading(true);
                    const res = await ClikTracker.createGroup(name, funnel, groupLinks);
                    swal(res.message);
                    history.push("/groups");  
                }catch(e){
                    swal(`Could not update ${name}`);
                    console.log(e.message);
                }finally{
                    setLoading(false)
                }
    }

    if(loading){
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title={`Loading ${name}`} /> 
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/groups">Groups</Link></Breadcrumb.Item>
                <Breadcrumb.Item active><i>Create Group</i></Breadcrumb.Item>
            </Breadcrumb>
                        <Container className="centralise">
                            
                        <Form onSubmit={onAdd}>
                            <Row xs={2}>
                                <Col>
                                    <h6>Group Name</h6>
                                    <FormControl required type="name" name="name" placeholder="Group Name" value={name} onChange={e=>setName(e.target.value)} />
                            
                                </Col>
                                <Col>
                                    <Button variant="success" className="hover dropShadow" type="submit">
                                        <strong>Add</strong>
                                    </Button>
                                </Col>
                            </Row>
                           
                            <br/>
                            {/* <Checkbox label="Is Funnel" onChange={(e) => setFunnel(e.target.checked)} checked={funnel} width={63} height={25} /> */}
                            <br/>
                             
                            <Table variant="success" className="dropShadow">
                                <thead>
                                    <tr>
                                        <th>INCLUDE</th>
                                        <th>NAME</th>
                                        <th>LINKS</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {links.map(cliktracker =>
                                        <tr key={cliktracker._id}>
                                            <td><Switch onChange={(value)=>onToggleLinkToGroup(cliktracker._id, value)} checked={groupLinks.indexOf(cliktracker._id)!==-1} width={63} height={25} /></td>
                                            <td><Detail text={cliktracker.name}/></td>
                                            <td><Detail text={cliktracker.short_url}/>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                                    overlay={<Tooltip id="tag1">{cliktracker.full_url}</Tooltip>}>
                                                    <Chip label="LONG URL"/>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table> 
                            <br/>
                            <button id="group" style={{display:"none"}} type="submit"/>
                        </Form>
                    </Container>
            
        </AppBar>
    )
}

export default PageGroupAdd
