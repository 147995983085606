import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import logo from "../images/logo.png";
import { Link, useHistory } from 'react-router-dom';
import { ContextSetReferral, ContextSetRegisterUrl, ContextSetShareLink, ContextSetShareText, ContextSetUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import PoweredByBOS from '../components/PoweredByBOS'; 

function PageLogin() {

    
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const setUser = useContext(ContextSetUser);
    const setShareLink = useContext(ContextSetShareLink);
    const setRegisterUrl = useContext(ContextSetRegisterUrl);
    const setReferral = useContext(ContextSetReferral);
    const setShareText = useContext(ContextSetShareText);


    // Authentication
    useEffect(() => {
        async function run(){
            try{
                        
                setLoading(true);
                const data = await API.GetAPI('/api/user');
                const {user, share_url, referral, register_url, share_text} = data;
                setUser(user);
                setShareLink(share_url);
                setRegisterUrl(register_url);
                setReferral(referral ? referral : "");
                setShareText(share_text);
                history.push('/links');
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
        }
        run();
    }, [history, setUser, setShareLink,setReferral, setRegisterUrl, setShareText]);

    const onFacebook = () =>  window.location.href = `${process.env.REACT_APP_BACKEND}/api/facebook/oauth/desktop`;

    const onGoogle = () => window.location.href = `${process.env.REACT_APP_BACKEND}/api/google/oauth/desktop`;

 
    return (
        <Container style={{paddingTop:120, paddingBottom:40}} className="centralise" fluid>
            
                <img src={logo} alt="logo" width={300}/>
                {
                loading ? 
                <div className="app"><AnimationLoading  width={250}  title="Loading"  /></div>
                
                :
                <Container fluid="sm">

                <Row xs={1} sm={2}> 
                    <Col>
                        <Button size="lg" disabled={loading} style={{borderRadius:40}} onClick={onFacebook} variant="light" className="round hover dropShadow">
                            <img src="https://img.icons8.com/fluency/30/000000/facebook-new.png" alt="F"/>
                            {" "}
                            <strong>Continue With Facebook</strong>
                        </Button>
                    </Col>
                    
                    <Col>
                        <Button size="lg" disabled={loading} style={{borderRadius:40}} onClick={onGoogle} variant="light" className="round hover dropShadow">
                            <img src="https://img.icons8.com/fluency/30/000000/google-logo.png" alt="G"/>
                            {" "}
                            <strong>Continue With Google</strong>
                        </Button>
                    </Col>

                   
                </Row>  
                </Container>
                }
                <br/>
                <h5>Use other options to <Link to="/signin">login</Link></h5>
 
                <PoweredByBOS />
                <br/><br/><br/><br/>
        </Container>
    )
}

export default PageLogin
