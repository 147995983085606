
// React Bootstrap Imports
import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import logo from "../images/cliktracker.png";
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader, SubMenu} from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import { IconContext, } from 'react-icons';
import { FaLayerGroup,FaGlobeEurope, FaLink, FaListAlt, FaPowerOff} from 'react-icons/fa';
import { MdRotateRight } from 'react-icons/md';
import { BiStats} from 'react-icons/bi';
import { FiLink2 } from 'react-icons/fi';
import { ContextUser } from '../App';
import { HiSpeakerphone } from 'react-icons/hi';
import { AiFillBell, AiFillSetting } from 'react-icons/ai';
import Cookies from 'js-cookie';
import MobileBar from './MobileBar';

const colorSelected = "#E6E6E6";
const iconSize = 24;

function AppBar({children}) {

    const history = useHistory();
    const user = useContext(ContextUser);
    const [size, setSize] = useState(window.innerWidth);


    const onDashboard = () => {
        const access_token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
        const url = `${process.env.REACT_APP_DASHBOARD_URL}/oauth?appToken=${access_token}&appName=Clik-Tracker`;
        window.open(url, "_blank");
    }

    const onLogout = () => {
        Cookies.set(process.env.REACT_APP_COOKIE_KEY, null);
        history.push("/");
        swal("Logout", 'Come back again', 'success');
    }

    
    useEffect(() => {
        const updateSize = () => setSize(window.innerWidth)
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    

    if(size <= 800){
        return (
            <div>
                <main style={{paddingTop:0}}>
                    {children}
                </main>
                <MobileBar/>
            </div>
        )
    }
    

    return (
        <div>
            <ProSidebar style={{position:"fixed"}} collapsed={true} color="#292929" >
                <SidebarHeader>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="EMP"><h6>Clik Tracker</h6></Tooltip>}>
                        <Menu iconShape="circle">
                            <SubMenu title="Clik Tracker" icon={<img width={50} alt="logo" src={logo} />}>
                                <MenuItem>{user?.name || ""}</MenuItem>
                                <MenuItem>Clik Tracker</MenuItem>
                            </SubMenu>
                        </Menu>
                    </OverlayTrigger>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="square">

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Links"><h6>Links</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/links')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><FaLink /></IconContext.Provider>}>Links</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Groups"><h6>Groups and Funnels</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/groups')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><FaLayerGroup /></IconContext.Provider>}>Groups</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Stats"><h6>Statistics</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/statistics')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><BiStats /></IconContext.Provider>}>Statistics</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Domains"><h6>Domains</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/domains')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><FaGlobeEurope /></IconContext.Provider>}>Domains</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Rotation"><h6>Link Rotation</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/rotations')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><MdRotateRight /></IconContext.Provider>}>Link Rotation</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="CTA"><h6>Call to Action</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/cta')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><HiSpeakerphone /></IconContext.Provider>}>Call to Action</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Dashboard"><h6>Dashboard</h6></Tooltip>}>
                            <MenuItem onClick={onDashboard} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><FaListAlt /></IconContext.Provider>}>Dashboard</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Settings"><h6>Settings</h6></Tooltip>}>
                            <MenuItem onClick={()=>history.push('/settings')} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><AiFillSetting /></IconContext.Provider>}>Settings</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Logout"><h6>Logout</h6></Tooltip>}>
                            <MenuItem onClick={onLogout} icon={<IconContext.Provider value={{ color: colorSelected, size:iconSize}}><FaPowerOff /></IconContext.Provider>}>Logout</MenuItem>
                        </OverlayTrigger>
                        
                    </Menu>
                </SidebarContent>
            
            </ProSidebar>
            <main style={{paddingLeft:80, paddingTop:0}}>
                {children}
            </main>
        </div>
    )
}

export default AppBar
