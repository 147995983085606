import React, { useState } from 'react'
import { Button, Container, Form, FormControl } from 'react-bootstrap';
import logo from "../images/logo.png";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import Cookies from 'js-cookie';

function PageSignUpEmail() {
    const [loading, setLoading] = useState(false);
   
    const onEmail = async (e) => {
        e.preventDefault();
        try{
            setLoading(true);
            const user = {
                name: e.target.name.value,
                email: e.target.email.value,
            }

            const res = await API.PostAPI('/api/email/create', user);
            if(res.result){
                swal('Sign In', res.message, 'success');
            }else{
                swal('Sign In', 'Something happened. Check your internet connection', 'info');
            }
        }catch(error){
            console.log(error.message);
        }finally{
            setLoading(false);
        }
    }
 
    return (
        <Container style={{paddingTop:40, paddingBottom:40}} className="centralise" fluid>
            
                <img src={logo} alt="logo" width={250}/>
                {loading ? 
                <div className="app"><AnimationLoading width={250} title="Loading"/></div>
                
                :
                <div> 
                    <br/>
                    <Form onSubmit={onEmail}>
                        <Form.Label>Name</Form.Label>
                        <FormControl type="name" required name="name" aria-label="name" maxLength={100} />
                        <br/>
                        <Form.Label>Email</Form.Label>
                        <FormControl type="email" required name="email" aria-label="email" />
                        <Button disabled={loading} type="submit" variant="light" className="round hover dropShadow">
                            <img src="https://img.icons8.com/fluency/24/000000/email.png" alt="Email"/>
                            {" "}
                            <strong>Create Account</strong>
                        </Button>
                    </Form>
                    
                    <hr/>
                    <h4>I Already Have an <Link to="/signin">Account</Link></h4>
                </div>  
                }

                <br/><br/><br/><br/>
        </Container>
    )
}

export default PageSignUpEmail
