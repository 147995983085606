import React, { useEffect, useState } from 'react'
import * as ClikTracker from "../utils/cliktracker";
import { Link, useParams } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { Breadcrumb, Button, Container, Form, FormControl, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";
import { AnimationLoading } from '../components/Lottie';
import Detail from '../components/Detail';
import { Chip } from '@mui/material';
import swal from 'sweetalert';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ColumnsForStats from '../utils/default_columns_for_stats.json';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }
  

function PageGroupEdit() {

    const {groupId} = useParams();
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState(null);
    const [name, setName] = useState("");
    const [funnel, setFunnel] = useState(false);
    const [links, setLinks] = useState([]);
    const [groupLinks, setGroupLinks] = useState([]);


    useEffect(() => {
        const run = async () => {
            try {
                const links = await ClikTracker.getClikTrackerLinks();
                setLinks(links);
            } catch (e) {
                console.log(e.message);
                setLinks([])
            }      
        }   
        run();
    }, []);


    useEffect(() => {
        const run = async ()=>{
            try {
                const res = await ClikTracker.getGroup(groupId);
                if(res.group){
                    setName(res.group.name);
                    setGroup(res.group);
                    setFunnel(res.group.funnel);
                    setGroupLinks(res.group.links);
                }
            } catch (e) {
                console.log(e);
            }      
        }   
        run();
    }, [groupId]);


    const onToggleLinkToGroup = (cliktrackerId, include) => {

        if(include){
            const link = groupLinks.find(id=>id === cliktrackerId);
            if(!link){
                setGroupLinks([...groupLinks, cliktrackerId]);
            }
        }else{
            const list = [];
            groupLinks.forEach(id => {
                if(id !== cliktrackerId){
                    list.push(id);
                }
            })
            setGroupLinks(list);
        }
    }

    
    const onUpdate = async (e) =>{ 
        e.preventDefault();
    
        try{
            setLoading(true);
            const name = e.target.name.value;
            const res = await ClikTracker.updateGroup(groupId, name, funnel, groupLinks);
            swal('Clik Tracker', res.message, 'info');
        }catch(e){
            swal(`Could not update ${name}`);
            console.log(e.message);
        }finally{
            setLoading(false)
        }
    }

    

    if(group === null || loading){
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title={`Loading ${name}`} /> 
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/groups">Groups</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>{name}</Breadcrumb.Item>
            </Breadcrumb>
            <Container className="centralise">
                            
                        <Form onSubmit={onUpdate}>
                            <Button variant="success" type="submit">
                                <strong>Update {name}</strong>
                            </Button>
                            <br/>
                            <h6>Group Name</h6>
                            <FormControl required type="name" name="name" placeholder="Group Name" value={name} onChange={e=>setName(e.target.value)} />
                            {/* <Checkbox label="Is Funnel" onChange={(e) => setFunnel(e.target.checked)} checked={funnel} /> */}
                            
                            <br/>
                            <Table variant="success" className="dropShadow">
                                <thead>
                                    <tr>
                                        <th>INCLUDE</th>
                                        <th>NAME</th>
                                        <th>LINKS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {links.map((cliktracker, index) => 
                                        <tr key={cliktracker._id}>
                                            <td><Switch onChange={(value)=>onToggleLinkToGroup(cliktracker._id, value)} checked={groupLinks.indexOf(cliktracker._id)!==-1} width={63} height={25} /></td>
                                            <td><Detail text={cliktracker.name}/></td>
                                            <td>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                                        overlay={<Tooltip id="tag1">{cliktracker.full_url}</Tooltip>}>
                                                    <Chip label="LONG URL"/>
                                                </OverlayTrigger>
                                                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                                        overlay={<Tooltip id="tag2">{cliktracker.short_url}</Tooltip>}>
                                                    <Chip label="SHORT URL"/>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table> 
                        <br/>
                        <button id="group" style={{display:"none"}} type="submit"/>
                    </Form>
                </Container>
        </AppBar>
    )
}

export default PageGroupEdit
