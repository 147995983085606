import AppBar from "../components/AppBar"
import React, { useEffect, useState } from 'react'
import * as ClikTracker from "../utils/cliktracker";
import { DataGrid } from '@mui/x-data-grid';
import { OverlayTrigger, Tooltip, Button, Modal, Table } from "react-bootstrap";
import { FcAndroidOs } from "react-icons/fc";
import { FaAppStoreIos, FaLaptop, FaMobile, FaWindows } from "react-icons/fa";
import { BsCloudDownloadFill } from "react-icons/bs";
import { SiMacos } from "react-icons/si";
import ToolTip from "../components/ToolTip";
import { GrMore } from "react-icons/gr";
import { ExportToCsv } from 'export-to-csv';
import swal from 'sweetalert'


function PageStatistics() {

    const [popup, setPopup] = useState(false);
    const [linkId, setLinkId] = useState(null);
    const [statisticsFromDatabase, setStats] = useState([]);
    const [size, setSize] = useState(window.innerWidth);

    const MOBILE_WIDTH = 800;

    useEffect(() => {
        const updateSize = () => setSize(window.innerWidth)
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const ColumnsForStats = {
        "columns": [
            {
                "field": "id",
                "hide": true
            },
            {
                "field": "name",
                "headerName": "Name",
                "width": 150,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="name1">Details of Links</Tooltip>}>
                    <h6><strong>Detail</strong></h6>
                </OverlayTrigger>,

                renderCell: params => {
                    const name = params.value.split("$$")[0];
                    const long_url = params.value.split("$$")[1];
                    const short_url = params.value.split("$$")[2];

                    return <OverlayTrigger placement="bottom" delay={{ show: 1000, hide: 1600 }}
                        overlay={<Tooltip id="name2">
                            <div>
                                <h6><strong>Details</strong></h6>
                                <h6>Name: {name}</h6>
                                <h6>Long Link: {long_url}</h6>
                                <h6>Short Link: {short_url}</h6>
                            </div>
                        </Tooltip>}>
                        <h6>{name}</h6>
                    </OverlayTrigger>
                }
            },
            {
                "field": "totalclicks",
                "headerName": "TC",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">Total Clicks</Tooltip>}>
                    <h6><strong>TC</strong></h6>
                </OverlayTrigger>
            },
            {
                "field": "uniqueclicks",
                "headerName": "UC",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="name1">Unique Clicks</Tooltip>}>
                    <h6><strong>UC</strong></h6>
                </OverlayTrigger>,
            },
            {
                "field": "fakeclicks",
                "headerName": "FC",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="name1">Fake Clicks</Tooltip>}>
                    <h6><strong>FC</strong></h6>
                </OverlayTrigger>,
            },
            {
                "field": "desktop",
                "headerName": "Desktop",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">Total Desktop/Computer Clicks</Tooltip>}>
                    <FaLaptop color="#22043b" size={30} />
                </OverlayTrigger>
            },
            {
                "field": "windows",
                "headerName": "Win",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">Windows Clicks</Tooltip>}>
                    <FaWindows color="#3c5091" size={30} />
                </OverlayTrigger>
            },
            {
                "field": "mac",
                "headerName": "Mac",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">MacOs Clicks</Tooltip>}>
                    {/* <FaApple color="" size={30}/> */}
                    <SiMacos size={30} />
                </OverlayTrigger>
            },
            {
                "field": "mobile",
                "headerName": "📱",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">All Mobile Clicks</Tooltip>}>
                    <FaMobile size={30} />
                </OverlayTrigger>
            },
            {
                "field": "android",
                "headerName": "Android",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">Andriod Clicks</Tooltip>}>
                    <FcAndroidOs size={30} />
                </OverlayTrigger>
            },
            {
                "field": "ios",
                "headerName": "iOS",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable": true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="text2">iOS</Tooltip>}>
                    <FaAppStoreIos size={30} color="#0296e6" />
                </OverlayTrigger>
            },
            {
                "field": "actions",
                "headerName": "ACTIONS",
                "type": "number",
                "width": 90,
                "editable": false,
                "resizable": true,
                renderCell: params => <div>
                    <ToolTip text="Show More">
                        <Button variant="success" className="hover dropShadow" onClick={() => {
                            setPopup(true);
                            setLinkId(params.value);
                        }}>
                            <GrMore color="white" />
                        </Button>
                    </ToolTip>
                </div>
            }
        ],
        "rows": [],
        "initialState": {
            "columns": {
                "columnVisibilityModel": {
                    "id": false
                }
            }
        }
    }


    const [loading, setLoading] = useState(true);
    const [statistics, setStatistics] = useState(ColumnsForStats);

    const onExport = () => {
        const copies = [];

        statistics.rows.forEach(link => {
            copies.push({
                NAME: link.name.replace(/\$.*/, ''),
                TOTAL_CLICKS: link.totalclicks,
                UNIQUE_CLICKS: link.uniqueclicks,
                FAKE_CLICKS: link.fakeclicks,
                LONG_URL: link.longurl,
                SHORT_URL: link.shorturl,
                DESKTOP: link.desktop,
                WINDOWS: link.windows,
                MAC: link.mac,
                MOBILE: link.mobile,
                ANDROID: link.android,
                IOS: link.ios
            });
        });

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: false,
            showTitle: false,
            title: 'FB Scraper',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(copies);
        swal('Cliktracker', 'CSV download successfully. Check your download folder', 'success');
    }



    useEffect(() => {

        const run = async () => {
            try {
                setLoading(true);
                const links = await ClikTracker.getClikTrackerLinks();



                const ids = [];
                links.forEach(link => ids.push(link._id));
                const statisticsFromDatabase = await ClikTracker.getStatistics(ids);
                const statisticsInfo = ColumnsForStats;

                setStats(statisticsFromDatabase.links);

                statisticsInfo["rows"] = statisticsFromDatabase.links.map(link => {
                    const id = link.link;
                    const short_url = links.find(link1 => link1._id === id)?.short_url;
                    return {
                        id: id,
                        name: `${link.name}$$${link.url}$$${short_url}`,
                        totalclicks: link.total_clicks,
                        uniqueclicks: link.unique_clicks,
                        fakeclicks: link.bots,
                        longurl: link.url,
                        shorturl: short_url,
                        desktop: link.desktop,
                        windows: link.windows,
                        mac: link.mac,
                        mobile: link.smartphone + link.tablet + link.phablet + link.phone,
                        android: link.android,
                        ios: link.ios,
                        actions: id
                    }
                });

                setStatistics(statisticsInfo);

            } catch (e) {
                console.log(e.message);
            } finally {
                setLoading(false);
            }
        }
        run();
    }, []);

    return (

        <AppBar>
            <div style={{ height: "95vh", width: '100%' }}>
                <DataGrid {...statistics} loading={loading} components={{ Toolbar: null }} />
            </div>

            {/*
                size > MOBILE_WIDTH ? <Button variant="success" onClick={onExport}>
                    <BsCloudDownloadFill />
                    <strong>Export</strong>
                </Button> : null
            */}

            <Modal style={{ zIndex: 12000 }} size={size <= MOBILE_WIDTH ? "lg" : "xl"} fullscreen={true} show={popup} onHide={() => setPopup(false)} aria-labelledby="modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">More</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {
                        linkId ?
                            <>
                                <div className="centralise">
                                    <h6>{statisticsFromDatabase.find(link => link.link === linkId).name}</h6>
                                    <hr />
                                </div>
                                <Table variant="success">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            {
                                                size > MOBILE_WIDTH ? <th>DEVICE</th> : null
                                            }
                                            {
                                                size > MOBILE_WIDTH ? <th>BROWSER</th> : null
                                            }

                                            <th>{size > MOBILE_WIDTH ? "COUNTRY" : "CNTRY"}</th>
                                            <th>OS</th>
                                            <th>IP</th>
                                            {
                                                size > MOBILE_WIDTH ? <th>DATE</th> : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            statisticsFromDatabase.find(link => link.link === linkId).clicks.map((click, index) =>
                                                <tr key={'click' + index}>
                                                    <td>{index + 1}</td>
                                                    {
                                                        size > MOBILE_WIDTH ? <td>{click.device.type.toUpperCase()}</td> : null
                                                    }
                                                    {
                                                        size > MOBILE_WIDTH ? <td>{click.client.family} ({click.client.version})</td> : null
                                                    }

                                                    <td>{click.geo.country}</td>
                                                    <td>{click.os.family}</td>
                                                    <td>{click.ip}</td>
                                                    {
                                                        size > MOBILE_WIDTH ? <td>{click.updatedAt}</td> : null
                                                    }

                                                </tr>
                                            )
                                        } 
                                    </tbody>
                                </Table>
                            </>
                            : null
                    }

                </Modal.Body>
            </Modal>
        </AppBar>
    )
}

export default PageStatistics
