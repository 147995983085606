import { Checkbox, FormControlLabel, Slider, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, FormControl, InputGroup } from 'react-bootstrap'
import { MdRotateRight } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import AppBar from '../components/AppBar'
import { AnimationLoading } from '../components/Lottie'
import * as ClikTracker from "../utils/cliktracker";

function PageRotationEdit() {

    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [linkCount, setLinkCount] = useState(2);
    const [name, setName] = useState("");
    const [code, setCode] = useState('');
    const [urls, setUrls] = useState(new Map());
    const [random, setRandom] = useState(false);
    const [dummyArray, setDummyArray] = useState([0,0]);

    useEffect(() => {
        const list = []
        for(let i= 0; i < linkCount; i++){
            list.push(i);
        }
        setDummyArray(list);
    }, [linkCount])

    
    useEffect(() => {
        async function run(){
            try{
                setLoading(true);
                const rotation = await ClikTracker.getRotator(id);
                setName(rotation.name);
                setCode(rotation.code)
                setRandom(rotation.random)
                const map = new Map();
                rotation.urls.forEach((link, index) =>map.set(index, link));
                setLinkCount(rotation.urls.length);
                setUrls(map)
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
        }
        run();
    }, [id]);

    const onUrl = (value, index) => {
        if(urls.get(index)){
            const link = urls.get(index);
            link.url = value;
            urls.set(index, link);
        }else{
            urls.set(index, {url:value, weight:1});
        }
        const newMap = new Map(urls)
        setUrls(newMap)
    }

    const onWeight = (value, index) => {
        if(urls.get(index)){
            const link = urls.get(index);
            link.weight = value;
            urls.set(index, link);
        }else{
            urls.set(index, {url:"", weight:value});
        }
        const newMap = new Map(urls)
        setUrls(newMap)
    }

    const onAdd = async () => {

        //create payload to send to backend
        const payload = {
            name:name, 
            code:code,
            random:random,
            urls: Array.from(urls.values()),
        }

         //verify that payload is valid
         if(payload.name.replace(/\s/gmi, '') ===""){
            return await swal('Clik Tracker Rotator', 'Please enter name', 'info');

        }

        //verify that payload is valid
        if(payload.code.replace(/\s/gmi, '') ===""){
            return await swal('Clik Tracker Rotator', 'Please enter code', 'info');
        }

        //verify that payload is valid
        if(payload.urls.length < 2){
            return await swal('Clik Tracker Rotator', 'Please enter select 2 or more links to rotator', 'info');
        }

        //verify that payload is valid
        if((payload.urls.forEach((link, count) => link.url.replace(/\s/gmi, '') === "" && count < linkCount))){
            return await swal('Clik Tracker Rotator', 'Please make sure that all rotating links urls are filled', 'info');
        }

        //verify that payload is valid
        if((payload.urls.forEach((link, count) => isNaN(parseInt(link.weight))  && count < linkCount ))) {
            return await swal('Clik Tracker Rotator', 'Please make sure that all rotating links weights are filled', 'info');
        }

        //verify that payload is valid
        if((payload.urls.forEach((link, count) => parseInt(link.weight) < 1  &&  parseInt(link.weight) > 100  && count < linkCount ))) {
            return await swal('Clik Tracker Rotator', 'Please make sure that all rotating links\' weights value should be between from 1-99', 'info');
        }

        try{
            setLoading(true);
            const res = await ClikTracker.updateRotator(id, payload);
            if(res.result){
                swal('Clik Tracker Rotator', res.message, 'success');
                history.push('/rotations')
            }else{
                swal('Clik Tracker Rotator', res.message, 'info');
            }
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    
    if(loading){
        return (
            <AppBar>   
                <Container>
                    <AnimationLoading title="Creating Link" />
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="#"><Link to="/rotations">Link Rotations</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{name}</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div className="centralise">
                    <Button variant="success" className="round hover dropShadow" onClick={onAdd}>
                        <MdRotateRight />
                        <strong>Update Rotation</strong>
                    </Button>
                </div>
                <br/>  <br/>
                <TextField id="outlined-basic" style={{width:"90%"}} label="Name of Rotator" variant="outlined" value={name} onChange={e=>setName(e.target.value)}/>
                <br/>
                <FormControlLabel
                    control={ <Checkbox label="Links Rotate Randomly" checked={random} onChange={e=>setRandom(e.target.checked)}/> }
                    label="Links Rotate Randomly"
                />
                <br/>
                <h6>Link</h6>
                <FormControl type="name" disabled defaultValue="https://app.mysocial360.com/cliktracker/rotate/" /> 
                <FormControl type="name" name="code" value={code} onChange={e=>setCode(e.target.value)} placeholder="Code" /> 
                <br/>
                <h6>Number of Links ({linkCount})</h6>
                <Slider value={linkCount} min={2} max={10} onChange={e=>setLinkCount(parseInt(e.target.value))} aria-label="Default" valueLabelDisplay="auto" />
                <br/>
                {
                    dummyArray.map((count, index) => 
                    <div key={index}>
                        <TextField style={{width:"90%"}} type="url" label={`Link ${index+1}`} variant="outlined" value={urls.get(index)?.url||""} onChange={e => onUrl(e.target.value, index)}/>
                        <TextField style={{width:"90%"}} disabled={random} InputProps={{ inputProps: { min: 1, max: 100 } }} type="number" label={random ? "Random" : "Weight"} variant="outlined" value={urls.get(index)?.weight||""} onChange={e => onWeight(e.target.value, index)}/>
                        <br/>
                    </div>
                    )
                }
            </Container>
        </AppBar>
    )
}

export default PageRotationEdit
