/* eslint-disable no-undef */

/*  */

import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Form, FormControl } from 'react-bootstrap'
import { AiFillCopy } from 'react-icons/ai';
import swal from 'sweetalert';
import { ContextReferral, ContextRegisterUrl, ContextShareLink, ContextUser, ContextSetUser, ContextShareText } from '../App';
import SocialShare from '../components/SocialShare';
import * as API from "../utils/api";

function ContentSettings() {

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState(false);
    const user = useContext(ContextUser);
    const setUser = useContext(ContextSetUser);
    const shareLink = useContext(ContextShareLink);
    const registerUrl = useContext(ContextRegisterUrl);
    const referral = useContext(ContextReferral);
    const [showPasswords, setShowPasswords] = useState(false);
    const [password2, setPassword2] = useState("");
    const [password3, setPassword3] = useState("");
    const shareText = useContext(ContextShareText);

     
    const copyToClipboard = async (text) => await navigator.clipboard.writeText(text);

    const onCode = async (e) => {
        e.preventDefault();
        try{
            
            const update = {
                code: e.target.code.value,
                notify: false
            }
            
            setLoading(true);
            const res = await API.PatchAPI(`/api/user/code`, update);
            if(res.result){
                user.code = res.id;
                user.code_updated = true;
                setUser({...user})
                swal('Affiliate ID', res.message, 'success');
            }else{
                swal('Affiliate ID', res.message, 'info');
            }
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }


    const onPassword = async (e) => {
        try{
            e.preventDefault();
            setLoading(true);

            const expression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

            const passwords = {
                password1: e.target.password1.value,
                password2: password2,
                password3: password3,
            }

            if(passwords.password1 === passwords.password2){
                swal('Password Change', 'Old and new password should be different', 'error');
            }else if(!passwords.password2.match(expression)){
                swal('Password Change', 'Passwords should have at least a symbol, upper and lower case letters and a number can be more than 8 characters', 'error');
            }else if(passwords.password2 !== passwords.password3){
                swal('Password Change', 'Passwords do not match', 'error');
            }else{
                const res = await API.PatchAPI('/api/user/password', passwords);
                swal('Password Change', res.message, res.result ? 'success':'info');
    
                //clear fields
                setShowPasswords(false);
                setPassword2("");
                setPassword3("")
                e.target.password1.value = "";
            }

            
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    const onResetPassword = async () => {
        try{
            setLoading(true);
            const info = { email: user.email }
            const res = await API.PostAPI('/api/email/forgot', info);
            if(res.result){
                swal('Password Reset', res.message, 'success');
            }else{
                swal('Password Reset', 'Something happened. Check your internet connection', 'info');
            }
        }catch(error){
            console.log(error.message);
        }finally{
            setLoading(false);
        }
    }
 
    return (
        <Container>
            <h1>Settings</h1>
            <div className="centralise">
            <img style={{borderRadius:"50%"}} className="dropShadow" src={user?.image || ""} alt="profile" width={100} />
                                <h4>{user?.name || ""}</h4>
                                <h6><i>{user?.email || ""}</i></h6>
                               
                                {referral !== "" ?
                                    <p>Referred by <strong>{referral}</strong></p>
                                :null}
                                <hr/>
                                <br/>
                                <h6>{shareText}</h6>
                                <br/>
                                <h6 style={{textAlign:"left"}}>Affiliate Share Link</h6>
                                <br/>
                                <FormControl style={{float:"left", borderTopRightRadius:0, borderBottomRightRadius:0, width:window.innerWidth - 180, maxWidth:900}} label="Affiliate Share Link" defaultValue={registerUrl} disabled aria-label="Affiliate Share Link"/>
                                <Button style={{float:"left"}}  variant="success" onClick={()=>{copyToClipboard(registerUrl); swal('Affiliate Share Link', 'Copied Link', 'success')}}>
                                    <AiFillCopy/>
                                </Button>
                                <br/><br/><br/><br/>
                                <h6 style={{textAlign:"left"}}>Direct Download Link</h6>
                                <br/>
                                <FormControl style={{float:"left", borderTopRightRadius:0, borderBottomRightRadius:0, width:window.innerWidth - 180, maxWidth:900}} label="Direct Download Link" defaultValue={shareLink} disabled aria-label="Direct Download Link"/>
                                <Button style={{float:"left"}} variant="success" onClick={()=>{copyToClipboard(shareLink);  swal('Direct Download Link', 'Copied Link', 'success')}}>
                                    <AiFillCopy/>
                                </Button>
                                <br/><br/><br/><br/>
                                <h6 style={{textAlign:"left"}}>Register as Affiliate</h6>
                                <br/>
                                <FormControl style={{float:"left", borderTopRightRadius:0, borderBottomRightRadius:0, width:window.innerWidth - 180, maxWidth:900}} label="Register as Affiliate Link" defaultValue={`${process.env.REACT_APP_BACKEND}/affiliate/register`} disabled aria-label="Register as Affiliate Link"/>
                                <Button style={{float:"left"}}  variant="success" onClick={()=>{copyToClipboard(`${process.env.REACT_APP_BACKEND}/affiliate/register`); swal('Register as Affiliate Link', 'Download Link', 'success')}}>
                                    <AiFillCopy/>
                                </Button>
                                <br/>
                                <br/><br/>
                                <SocialShare url={shareLink} title="Clik Tracker" description="Get this amazing coaching app" image="https://firebasestorage.googleapis.com/v0/b/clik-tracker.appspot.com/o/image.png?alt=media&token=bb88a409-da27-4cfc-908c-fa9959a3ada4" />
            <br/>
            </div>
           
            <h3>Password</h3>
            <br/>
            <Form onSubmit={onPassword}>

            <Form.Label>Old Password</Form.Label>
            <FormControl disabled={loading} type={showPasswords ? "text" : "password"} placeholder="Old Password" required name="password1" aria-label="password"/>
            <br/>
            <Form.Label>New Password</Form.Label>
            <FormControl disabled={loading} type={showPasswords ? "text" : "password"} value={password2} onChange={e=>setPassword2(e.target.value)} placeholder="New Password" required name="password2" aria-label="password"/>
            <br/>
            <Form.Label>Confirm New Password</Form.Label>
            <FormControl disabled={loading} type={showPasswords ? "text" : "password"} value={password3} onChange={e=>setPassword3(e.target.value)} placeholder="Confirm New Password" required name="password3" aria-label="password"/>
            <br/><br/>
            <Button disabled={loading} type="submit" variant="success" className="round hover dropShadow">
                <img src="https://img.icons8.com/ios-filled/20/ffffff/key.png" alt="password" />{" "}
                <strong>{loading?"Loading" :"Change Password"}</strong>
            </Button>
            {" "}
            <Button disabled={loading} as="a" target="_blank" onClick={onResetPassword} variant="outline-success" className="hover round dropShadow">
                <img src="https://img.icons8.com/ios-glyphs/24/007bff/email.png" alt="profile"/>
                {" "}
                Send New Password
            </Button>
            {" "}
            <Button disabled={loading} as="a" target="_blank" onClick={()=>setShowPasswords(!showPasswords)} variant="outline-success" className="hover round dropShadow">
               {showPasswords ? "Hide" : "Show"}
            </Button>
            </Form>   

            <br/>
                                {
                                    user?.code_updated ? <h6>Affiliate ID: <strong>{user?.code}</strong></h6>
                                  :
                                <Form onSubmit={onCode}>
                                    <Form.Label><h4>Affiliate ID: <strong>{user?.code}</strong></h4></Form.Label>
                                    <br/>
                                    <FormControl required name="code" defaultValue={user?.code} type="code" size="lg" placeholder="Affiliate ID" />
                                    
                                    <br/><br/>
                                    <Button size="lg" disabled={loading} variant={loading?"info":"success"} type="submit" className="round hover dropShadow">
                                        <img src="https://img.icons8.com/ios-filled/20/ffffff/digi-id.png" alt="join"/>{" "}
                                        <strong>{loading?"Loading...":"Update Affiliate ID"}</strong>
                                    </Button>
                                </Form>
                                 }
            <br/><br/> <br/><br/>     
        </Container>
    )
}

export default ContentSettings
